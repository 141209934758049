
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import Pagination from "@/comp/Pagination/index.vue";
import { exportGiftcodeRecordList, getGiftcodeRecordList } from "@/api/request/item";
import { saveAs } from "@/api/request/base";

//组件
@Component({
  name: "GiftRecord",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //请求数据
  private listQuery: any = {
    //页码
    row: 20,
    page: 1,

    //零食数据
    useridStr: "",

    //数据
    code: "",
    userid: 0,
    excel: false,
    code_batch: "",
  };

  //定义变量
  private list: any[] = []; //数据
  private total: number = 0; //总数
  private listLoading: boolean = true; // 列表加载

  //创建调用
  created() {
    //获取列表
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.userid = Number(this.listQuery.useridStr);

    //获取数据
    const { data } = await getGiftcodeRecordList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleSearch(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //导出处理
  private async exportHandler() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.excel = true;
    this.listQuery.userid = Number(this.listQuery.useridStr);

    //获取数据
    const { data } = await exportGiftcodeRecordList(this.listQuery);

    //保存数据
    saveAs(data, "礼包码领取记录");

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }
}
